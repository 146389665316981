<template>
  <v-container>
    <div v-if="programa">
      <!-- Pagination -->
      <div class="print-only">
        <v-row justify="end" no-gutters>
          <v-col cols="10"></v-col>
          <v-col cols="2" style="font-size: 10px">
            <div class="float-right">
              {{ $t('pageText', [1, paginasTotal]) }}
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- Header -->
      <programas-header
        :programa="programa"
        :fazenda="fazenda"
        :semente="semente"
        :conta="conta"
        :safra="safra.nome"
      ></programas-header>

      <!-- Aplicações -->
      <div
        v-for="planejamento in programa.planejamentos"
        :key="planejamento.id"
      >
        <!-- Ordinal e data da aplicação -->
        <programas-item
          :display-efficacies="displayEfficacies"
          :planejamento="planejamento"
          :bag-price="bagPrice"
        ></programas-item>

        <v-divider />
        <!-- Pagination -->
        <div class="print-only" v-if="pageBreak(planejamento.aplicacao)">
          <v-row justify="end" class="page-begin">
            <v-col cols="10"></v-col>
            <v-col cols="2" style="font-size: 10px">
              <div class="float-right">
                {{ $t('pageText', [paginasTotal, 2]) }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div>
        <programas-footer
          :programa="programa"
          :usuario="usuario"
        ></programas-footer>
      </div>

      <!-- Footer -->
      <footer class="page-end">
        <v-row class="no-gutters pl-4 pr-4" justify="end" align="center">
          <v-col cols="9" class="caption text-left"
            >{{ $t('learn_more') }}<br /><b>www.digifarmz.com</b></v-col
          >
          <v-col cols="3">
            <img
              src="img/logos/logo-digifarmz.svg"
              style="width: 80%; float: left"
            />
          </v-col>
          <v-col cols="1" class="print-only"></v-col>
        </v-row>
        <v-row class="amber accent-3 pa-2"></v-row>
      </footer>
    </div>
  </v-container>
</template>

<script>
import ProgramasHeader from '@/modules/reports/components/programas/Header.vue'
import ProgramasItem from '@/modules/reports/components/programas/Item.vue'
import ProgramasFooter from '@/modules/reports/components/programas/Footer.vue'
import reportService from '@/modules/reports/services/reportService'
import { mapActions } from 'vuex'

export default {
  name: 'ReportsPrograma',
  components: {
    ProgramasHeader,
    ProgramasItem,
    ProgramasFooter,
  },

  data() {
    return {
      programa: null,
      fazenda: null,
      conta: null,
      semente: null,
      safra: null,
      displayEfficacies: false,
    }
  },

  computed: {
    paginasTotal() {
      return !this.programa
        ? 1
        : parseInt(this.programa.planejamentos.length / 8) + 1
    },

    bagPrice() {
      return this.fazenda.bag_price.length
        ? this.fazenda.bag_price.find(
            (bag) => bag.crop_id === this.programa.semente.crop_id
          )?.price
        : this.fazenda.valor_saca
    },
  },

  methods: {
    ...mapActions('crops', ['setCurrentCrop', 'fetchCrops']),
    ...mapActions('seasonsCrops', ['fetchDiseases', 'setCurrentSeasonCrop']),

    listPrograma() {
      return reportService
        .program(this.$route.query.id)
        .then(async (response) => {
          this.programa = response.data
          this.fazenda = response.data.fazenda
          this.conta = response.data.conta
          this.semente = response.data.semente
          this.usuario = response.data.usuario
          this.safra = response.data.safra

          await this.fetchCrops()
          this.setCurrentSeasonCrop({ id: this.safra.season_crop_id })
        })
        .catch(() => {
          this.$root.$emit('notify', 'error', this.$t('cultivar.carregar.erro'))
        })
    },

    waitAndPrint() {
      setTimeout(() => {
        window.print()
      }, 1500) // 1,5s
    },

    pageBreak(aplicacao) {
      return aplicacao === 7 && this.programa.planejamentos.length > 7
    },
  },
  async mounted() {
    await this.listPrograma()
    await this.setCurrentCrop(this.semente.crop_id)
    this.fetchDiseases().finally(() => {
      this.displayEfficacies = true
      this.waitAndPrint()
    })
  },
}
</script>

<style scoped>
.print-only {
  display: none;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  .print-only {
    display: block;
  }

  .display-only {
    display: none;
  }

  .page-end {
    page-break-after: always;
    bottom: 0;
    position: fixed;
    width: 100%;
  }

  .page-begin {
    page-break-before: always;
  }
}
</style>
